import * as React from "react";
import PublicationCard from "../components/publicationCard";

const Publications = ({ allPublications }) => {
    return(
        <>
        <section className="section-wrapper">
        <h2>Some Things I've Written</h2>
            <div className="publications-card-wrapper">
                {allPublications ? allPublications.map(publication => {
                    return(<PublicationCard publicationContent={publication} key={publication.id} />)
                }) : null}
            </div>

        </section>
        </>
    )
}

export default Publications