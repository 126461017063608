import * as React from "react";

const PublicationCard = ({ publicationContent }) => {

    const CONTENT_URL = "https://content.shaneducksbury.com"

    return(
        <>
            <div className="publication-card-wrapper">
                <div className="publication-card-image">
                    <img src={CONTENT_URL + publicationContent.PublicationShot.url} alt="" />
                </div>
                <div className="publication-content">
                <a href={publicationContent.Link} target="_blank"><h3>{publicationContent.Title}</h3></a>
                    <p>{publicationContent.Description}</p>
                </div>
            </div>
        </>
    )
}

export default PublicationCard